import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import DailyDispatch from "./DailyDispatch/DailyDispatch"
import DispatchDate from "./date/date"
import Settings from "./settings/app"
import { useState, useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../actions/session"


export default function MainApp() {
    const [appToShow, setAppToShow] = useState(<DailyDispatch />)
    const [key, setKey] = useState('dispatch')
    const dispatch = useDispatch()
    const list = useSelector((state) => state.stoplist)
    useEffect(()=>{
        switch(key){
            case 'dispatch': 
                setAppToShow(<DailyDispatch />)
                break; 
            case 'settings': 
                setAppToShow(<Settings />)
                break; 
            default: 
                setAppToShow(<DailyDispatch />)
        }

    }, [key])
	return(
        <Container className = "main-body">
            <Row className ='company'>
                <Col>
                    <h6 id="company-tag">AMERICAN BACKFLOW & PLUMBING SERVICES,INC.</h6>
                </Col>
            </Row>
            {
                key == 'dispatch'?
                    <>

                        <Row className ='sticky-options'>
                            <Col xs={8} sm md lg = {8}>
                                <DispatchDate />
                                
                            </Col>
                            <Col>
                                <br/>
                                <strong> {list.length} stops</strong>
                            </Col>
                        </Row>

                    </>
                : 
                    <Row className ='sticky-options'> 

                    </Row>

            }
            
            <Row id="scrollable-body">
                <Col>    
                   {appToShow}
                </Col>
            </Row>
            <Row className = 'sticky-nav'>
                <Col xs = {7} sm md lg = {7} >

                    <Tabs
                          activeKey={key}
                          onSelect={(k) => setKey(k)}
                          className="mb-3"
                          variant = 'underline'

                    >
                        <Tab eventKey="dispatch" title="Dispatch">
                        </Tab>
                         <Tab eventKey="settings" title="Settings">
                        </Tab>
                    </Tabs>   
                </Col>       
                <Col>
                    <Button 
                        variant ="dark"
                        onClick = { () => dispatch ( logout () ) }>
                        Log Out
                    </Button>
                </Col>
            </Row>
            
        </Container>
	)
}