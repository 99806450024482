import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import CardGroup from 'react-bootstrap/CardGroup';
import TestReport from "./TestReport"
import ServiceRow from "./ServiceRow"
import { useState, useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {requestStopServices, createAssembly} from "../../actions/stopsApp"

export default function AssemblyList(props) {
	const list = useSelector((state) => state.assemblylist)
	const addressID = useSelector((state) => state.addressID)
	const stopID = useSelector((state) => state.stopID)
	const [creating, setCreating] = useState(false)
	const [loadMe, setLoadMe] = useState(false)
	const dispatch = useDispatch() 

	const renderServices = list.map((service, index) => {
		return ( 
			<ServiceRow service={service} index = {index} />
		)
	})

	useEffect(()=>{	
		if(stopID != null){
			setLoadMe(true)
			dispatch(requestStopServices(stopID)).then((data, err) => {
					setLoadMe(false)
			})
		}
	}, [])

	const newAssembly = () => {
		setCreating(true)
		createAssembly(stopID, addressID).then((data, err) => {
			//request a new list
			dispatch(requestStopServices(stopID)).then((data, err) => {
				setCreating(false)

			})
		})
	}
	return(
		<Table responsive striped>
			<thead>
				<tr>
					<th>
						SN
					</th>
					<th>
						Location
					</th>
					<th>
						Service
					</th>
					<th>
						Ready 
					</th>
					<th>
						Status
					</th>
				</tr>
			</thead>
			<tbody>
				{
					list.length ?
						<>
							{
								loadMe ?
									<Spinner />
								: 
									<> {renderServices}</>
							}
						</>
							
						:
						<tr>
							<td colSpan = {5}>
								No assemblies to show  
							</td>
						</tr>
				}

				{
					creating ?
						<Spinner />
						:
						<Button
					size="sm"
					variant="light"
					onClick={ newAssembly }
				> +  </Button>

				}
				
			</tbody>
		</Table>

	)

}