import GoogleApple from "./GoogleApple"
import AssemblyList from "./AssemblyList"
import Invoice from "../invoice/Invoice"
import Tab from 'react-bootstrap/Tab';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import Tabs from 'react-bootstrap/Tabs';
import { useState, useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
	requestAddressDetails,
	requestStopServices,
	cleanStopServices,
	setAddressID,
	clearAddressID,
	setStopID,
	clearStopID, 
	updateStopState, 
	requestStoplist, clearStoplist, showNotTested, hideNotTested
} from "../../actions/stopsApp"




export default function AddressContact(props){
	const [myAddress, setMyAddress] = useState(null)
	const [addressLoading, setAddressLoading] = useState(false)
	useEffect(()=>{
		//load up stop address details
		if (props.stop.addressID != null) {
			setAddressLoading(true)
			requestAddressDetails(props.stop.addressID).then((data, err) => {
				setMyAddress(data)
				setAddressLoading(false)
			})
		}
	}, [])
	return(
		<Container>
			<Row>
				<Col>
					<h6>Contact Information </h6>
					<p>
						{
						myAddress ?
							<>
									{myAddress.contact_name} <br/> 
									<a href={ `tel:${myAddress.contact_phone  }`}>{myAddress.contact_phone  }
									</a>
							</>
						: 	
							<>waiting for contact info</>
						}
					</p>
				</Col>
				<Col xs={5}sm md lg={1}>
					<h6>links </h6>
					<GoogleApple address={myAddress} />
				</Col>
				
			</Row>
		</Container>
	)
}