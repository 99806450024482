import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import { useState, useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { requestAssemblyDetails, setReportID, 
setAssemblyID, updateServiceState, requestStopServices} from "../../actions/stopsApp"
import TestReport from "../reports/app.js"
import NotTested from "./NotTested"


export default function ServiceRow(props) {
    const dispatch = useDispatch(); 
    const assemblylist = useSelector( (state) => state.assemblylist )
    const [ready, setReady] = useState(true)
    const [showModal, setShowModal] = useState(false)
    const stopID = useSelector( (state) => state.stopID)

    useEffect(() => {
       // console.log("This is my assembly: ", props.service)
    }, [])

    useEffect(()=>{
        assemblylist[props.index].ready = ready
    }, [ready])

    const closeModalTrue = ()=>
        { 
            setShowModal(false) ; setReady( true) 
        }

    const closeModalFalse = ()=>
        { 
            setShowModal(false) ; 
            setReady( false) 
        }

    useEffect(()=>{
       
    },[ready])

    return (
        <>
            {
                showModal ? 
                    <NotTested  closeMeTrue = { closeModalTrue } closeMeFalse = { closeModalFalse} serviceID = {props.service.serviceID}/>
                : 
                    <> </>

            }
        <tr>
             <td
                    onClick={
                        () => {
                            dispatch(setReportID(props.service.testReportID))
                            dispatch(setAssemblyID(props.service.assemblyID))
                        }}
                         >{props.service.serial_number }
             </td >
             <td 
                onClick={
                    () => {
                        dispatch(setReportID(props.service.testReportID))
                        dispatch(setAssemblyID(props.service.assemblyID))
                    }}>{props.service.location }

            </td>
            <td>
                {props.service.serviceType}
            </td>

             <td>
                <Form.Check 
                    type = "checkbox"
                    checked = {props.service.ready}
                    onChange = {
                        (event) =>{
                             if(!event.target.checked){
                                setShowModal(true)
                            }else{
                                // set service state to true and refresh service list
                                updateServiceState( true,'', props.service.serviceID).then((data, err) =>{
                                    //request new list of services for stop
                                    dispatch(requestStopServices(stopID)).then((data, err) => {
                                    })
                                })

                            }
                        }
                    }
                />

             </td>
             <td>
                {props.service.serviceState}
             </td>

          </tr>
          {
            !props.service.ready ?
                <tr>
                    <td colSpan = {6}>
                        <i>{props.service.reason}</i>
                    </td>
                </tr>
            : 
                <>
                </>

          }
        </>
    )
}