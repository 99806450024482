import { useState, useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

export default function GoogleApple(props) {
    const [addressObj, setAddressObj] = useState(null)
    const [googleAddressLink, setGoogleAddressLink] = useState(null)
    const [appleAddressLink, setAppleAddressLink] = useState(null)

    useEffect(()=>{
        //console.log( "This is the address: ", props.address)
    }, [])

    useEffect(()=>{
        setAddressObj( props.address)
    }, [props.address])

    useEffect(() => {
        if (addressObj != null) {
            setGoogleAddressLink(`http://maps.google.com/?q=${encodeURIComponent(`${addressObj.street},
                                    ${addressObj.city}, ${addressObj.state}, ${addressObj.zipcode}`)}`)
            setAppleAddressLink(`http://maps.apple.com/?q=${encodeURIComponent(`${addressObj.street}, 
                            ${addressObj.city}, ${addressObj.state}, ${addressObj.zipcode}`)}`)
        }
    }, [addressObj])
    
    return (
        <Container>
                    <Row>
                        <Col> 
                            <a href={googleAddressLink}  target="_blank">
                                <Button size= "sm" variant = "link" onClick = {
                                    ()=>{
                                    }
                                }>Google  </Button>
                            </a>
                      
                            <a href={appleAddressLink} target="_blank"> 
                                <Button  size = "sm" variant = "link" onClick = {
                                    ()=>{
                                    }
                                }> 
                                    Apple
                                </Button>
                            </a> 
                        </Col>
                    </Row>
             
            
        </Container>
    )
}