import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import DispatchDate from "../date/date"
import MapApp from "../map/app.js"
import StopsApp from "../stops/App"
import { useState, useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

export default function DailyDispatch() {
	const [key, setKey] = useState('stops');
	const [appToShow, setAppToShow] = useState(<StopsApp />)

    return (
		<Container>
			
			<Row>
				<Col>
					<StopsApp />
				</Col>
			</Row>
			
        </Container>
     )
}