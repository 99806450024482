import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Stack from 'react-bootstrap/Stack';
import { useState, useEffect, useRef, useCallback, useContext} from "react";
import {useSelector, useDispatch} from "react-redux";
import {
	requestAddressDetails,
	requestStopServices,
	cleanStopServices,
	setAddressID,
	clearAddressID,
	setStopID,
	clearStopID, 
	updateStopState, 
	requestStoplist, clearStoplist, showNotTested, hideNotTested
} from "../../actions/stopsApp"
export default function StopHeader(props){
	const [addressLoading, setAddressLoading] = useState(false)
	const [myAddress, setMyAddress] = useState(null)
	const [timeBadge, setTimeBadge] = useState(null)
	const [completed, setCompleted] = useState(false)
	const [incomplete, setIncomplete] = useState(false)
	const [changeLoading, setChangeLoading] = useState( false) ; 
	const mydate = useSelector((state) => state.dispatchDate)
    const dispatch = useDispatch()
	const [commentlength, setCommentLength] = useState(250)

	useEffect(()=>{
		//load up stop address details
		if (props.stop.addressID != null) {
			setAddressLoading(true)
			requestAddressDetails(props.stop.addressID).then((data, err) => {
				setMyAddress(data)
				setAddressLoading(false)
			})
		}
	}, [])

	function formatTime(time24) {
		const [hours, minutes] = time24.split(':').map(Number);
		const ampm = hours >= 12 ? 'PM' : 'AM';
		const hours12 = hours % 12 || 12;
		return `${hours12}:${minutes.toString().padStart(2, '0')} ${ampm}`;
	}

	useEffect(() => {
		if (props.stop.startTime != "08:00" || props.stop.endTime != "16:00") {
			if(props.stop.startTime == props.stop.endTime){
				setTimeBadge(<Badge bg= "danger">{ formatTime(props.stop.startTime) }  </Badge>)
			}else{
				setTimeBadge(<Badge bg= "danger">{ formatTime( props.stop.startTime)} - {formatTime( props.stop.endTime) }  </Badge>)
			}
			
		} else {
			setTimeBadge(<> </>)
		}
	}, [])

	useEffect(()=>{
		if(props.stop.stopStatus == "COMPLETED" ){
			setCompleted(true)
		}else if(props.stop.stopStatus == "NOT TESTED"){
			setIncomplete(true)
		}
	}, [])

	return(
		<>
			<Container>
				<Row>
					<Col>
						{
							myAddress ?
								<p>
									<strong> {myAddress.location_name}</strong> <br/>
									{myAddress.street}, {myAddress.city}<br/>
									{myAddress.state} {myAddress.zipcode}
								</p>
							: 
								<> </>
						}
					</Col>
					<Col> 
						{timeBadge} 
						<br/>
						<br/>
						<Stack direction="horizontal">
							
							
						{
							completed ?
								<Badge bg = "success"> {props.stop.stopStatus} </Badge>
							: 
								<>
								</>
						}
						{
							incomplete ?
								<>
									<Badge bg = "secondary"> Incomplete </Badge>
								
								</>
							: 
								<>
								</>
						}
						
						</Stack>
					</Col>
				</Row>

				<Row>
					<Col>
						<>
							{
								props.stop.comment ?
									<>
										{
											props.stop.comment
										}
											
									</>
									:
									<></>
							}
						</>
					</Col>
				</Row>
				<Row>
					<Col>
						<br/>
						
					</Col>
				</Row>
			</Container>

		</>
	)
}