import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import InputGroup from 'react-bootstrap/InputGroup';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { useState, useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { requestBillingDetails, updateBillingDetails} from "../../actions/invoiceApp"


export default function BillingAddress(){
	const billingID = useSelector( (state) => state.billingID)
	const invoiceID = useSelector( (state) => state.invoiceID)

	const [initialBilling, setInitialBilling] = useState(null)
	const [name, setName] = useState(null)
	const [street, setStreet] = useState(null)
	const [city, setCity] = useState(null)
	const [state, setState] = useState(null)
	const [zipcode, setZipcode] = useState(null)
	const [email, setEmail] = useState(null)
	const [edit, setEdit] = useState(false)
	const [loading, setLoading] = useState(false)
	const [updating, setUpdating] = useState(false) 
	useEffect(()=>{
		if(initialBilling != null){
			setName(initialBilling.name)
			setStreet(initialBilling.street)
			setCity(initialBilling.city)
			setState(initialBilling.state)
			setZipcode(initialBilling.zipcode)
			setEmail(initialBilling.email)
		}
	}, [initialBilling])

	useEffect(()=>{
		if(invoiceID != null){
			//request billing details
			setLoading(true)
			requestBillingDetails(invoiceID).then((data, err) =>{
				setLoading(false) 
				setInitialBilling(data)
			})
		}
	}, [invoiceID])

	const save = () =>{
		let obj = {};
		obj.name = name;
		obj.street = street;
		obj.city = city; 
		obj.state = state;
		obj.zipcode = zipcode;
		obj.email = email; 
		updateBillingDetails(initialBilling.id, obj).then((data, err) =>{
			setLoading(true)
			requestBillingDetails(invoiceID).then((data, err) =>{
				setLoading(false) 
				setInitialBilling(data)
				setEdit(false)
			})
			
		}) 
	}

	return(

		<>{
			edit ? 
				<Container>				
					<Row>
						<Col xs = {8} sm md ={8} lg = {10}>
							<Form.Label> Billing To : </Form.Label>
							<InputGroup size= "sm">
								<InputGroup.Text>Name </InputGroup.Text>
								<Form.Control 
									value = {name}
									onChange= {
										(event) => setName(event.target.value)
									}
								/>
							</InputGroup>
							<InputGroup size= "sm">
								<InputGroup.Text>Street </InputGroup.Text>
								<Form.Control 
									value = {street}
									onChange= {
										(event) => setStreet(event.target.value)
									}
								/>
							</InputGroup>
							<InputGroup size= "sm">
								<InputGroup.Text>City</InputGroup.Text>
								<Form.Control 
									value = {city}
									onChange= {
										(event) => setCity(event.target.value)
									}
								/>
							</InputGroup >
							<InputGroup size= "sm">
								<InputGroup.Text>State</InputGroup.Text>
								<Form.Control 
									value = {state}
									onChange= {
										(event) => setState(event.target.value)
									}
								/>
							</InputGroup>
							<InputGroup size= "sm">
								<InputGroup.Text>Zipcode</InputGroup.Text>
								<Form.Control 
									value = {zipcode}
									onChange= {
										(event) => setZipcode(event.target.value)
									}
								/>
							</InputGroup>
							<InputGroup size= "sm">
								<InputGroup.Text>Email</InputGroup.Text>
								<Form.Control 
									value = {email}
									onChange= {
										(event) => setEmail(event.target.value)
									}
								/>
							</InputGroup>

						</Col>
						<Col>
							<Button 
								size= "sm"
								variant = "primary"
								onClick = {save}
								> Save </Button>
						</Col>
					</Row>
				</Container>

			: 
				<Container>
					<Row>
						<Col>
							Billing
						</Col>
						<Col>
							<Button 
								variant = "link"
								onClick = {() =>setEdit(true)}
							> 
								Edit
							</Button>
						</Col>
					</Row>
					<Row>
						<Col >
							<p>
								{name}<br/> {street}, {city}, {state} {zipcode} <br/>
							
								Email:  {email}
							</p>
						</Col>
					</Row>
				</Container>

		}</>
		
	)
	
}