import ListGroup from 'react-bootstrap/ListGroup';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import Card from 'react-bootstrap/Card';
import Spinner from 'react-bootstrap/Spinner';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import AccordionContext from 'react-bootstrap/AccordionContext';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import Col from 'react-bootstrap/Col';
import { useState, useEffect, useRef, useCallback, useContext} from "react";
import {useSelector, useDispatch} from "react-redux";
import ReadMore from "./Content"
import Stack from 'react-bootstrap/Stack';
import Invoice from "../invoice/Invoice"
import {
	requestAddressDetails,
	requestStopServices,
	cleanStopServices,
	setAddressID,
	clearAddressID,
	setStopID,
	clearStopID, 
	updateStopState, 
	requestStoplist, clearStoplist, showNotTested, hideNotTested
} from "../../actions/stopsApp"
import { format } from "date-fns"
import AssemblyList from "./AssemblyList"
import StopBody from "./StopBody"
import GoogleApple from "./GoogleApple"
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import StopHeader from "./StopHeader"

export default function StopCard(props) {
	const [key, setKey] = useState("services")
    const dispatch = useDispatch()
    const stopID = useSelector( (state) => state.stopID)
    const [changeLoading, setChangeLoading] = useState(false)
    const mydate = useSelector((state) => state.dispatchDate)

	return(
		<Accordion.Item eventKey ={props.stop.stopID}>
			<Accordion.Header >
				<StopHeader stop = {props.stop} />
			</Accordion.Header>
			<Accordion.Body onEnter = {
				()=> {
					dispatch( setStopID(props.stop.stopID) ).then((data, err) =>{
							console.log("Set stop id...", stopID)
	           		})
				}}
			>
				<StopBody stop = {props.stop} />	
				<br/>
				<Button 					
					variant="success"
					onClick = {
						()=>{
							setChangeLoading(true)
							updateStopState( props.stop.stopID, "COMPLETED").then( (data, err) =>{
								dispatch( clearStoplist() ).then((data, err) =>{
									dispatch(requestStoplist(mydate)).then((data, err) =>{
										setChangeLoading(false)
									})
								})									
							})
						}
				}>
					{
						changeLoading ?
							<Spinner />
						: 
							<>Send to office </>
					}
				</Button>		
			</Accordion.Body>
		</Accordion.Item>
	)
}