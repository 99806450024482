import { useState, useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { format, isSameDay } from 'date-fns'
import { determineNewDate } from "../../actions/stopsApp"

export default function DispatchDate() {
    const date = useSelector((state) => state.dispatchDate)
    const [mydate, setMyDate] = useState(null);

    const dispatch = useDispatch()

    useEffect(() => {
        
       if (date != null) {
            setMyDate(date)
        }
        
    }, [date])

    useEffect(() => {
        if(mydate  == '' ){
            dispatch(determineNewDate(format( new Date(), 'yyyy-MM-dd' ) ) ).then((data, err) => {
                  console.log("Changed the date")
                })
           
        }else if (!isSameDay(mydate, date)) {
                dispatch(determineNewDate(mydate) ).then((data, err) => {
                  console.log("Changed the date")
                })
       }
    }, [mydate])

    return (
        <Container>
            <Row>
                <Col>
                    <FloatingLabel label = "Date">
                        <Form.Control
                                type="date"
                                value={mydate}
                                onChange={
                                    (event) => setMyDate(event.target.value)

                                }
                            />
                    </FloatingLabel>
                </Col>
            </Row>
        </Container>
       )
}