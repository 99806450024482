import StopCard from "./StopCard"
import ListGroup from 'react-bootstrap/ListGroup';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Badge from 'react-bootstrap/Badge'
import Accordion from 'react-bootstrap/Accordion'
import Col from 'react-bootstrap/Col'
import Spinner from 'react-bootstrap/Spinner'
import { useState, useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { requestStoplist } from "../../actions/stopsApp"
import TestReport from "../reports/app.js"
import { reduceTimeWindows, reduceServiceTimes,
	clearDM, clearTW, clearOrd, clearIsRouted, requestStopServiceTime} from "../../actions/map"
import StartEnd from "../map/start_end"
import MapApp from "../map/app.js"

import {
    getTesterAddressList
} from "../../actions/settings"

import {
	setStopID,
	clearStopID
} from "../../actions/stopsApp"

import StopBody from "./StopBody"

export default function StopsApp() {
	const list = useSelector((state) => state.stoplist)
	const order = useSelector( (state) => state.stop_order)
	const [hasStops, setHasStops] = useState(false)
	const [stopsLoading, setStopsLoading] = useState(false)
	const mydate = useSelector((state) => state.dispatchDate)
	const routed = useSelector((state) =>state.routed)
	const dispatch = useDispatch();

	useEffect(()=>{
		if(mydate != null){
			dispatch( requestStoplist( mydate ) ).then((data, err) =>{
			})
		}
		
	}, [mydate])

	const renderlist = list.map((stop) => {
		return (
			<StopCard key={stop.stopID} stop={ stop } />
		)
	})

	return (
		<Container className= 'stops-body'>
			<Row> 
	            <Col>
	            	<TestReport />
	            	<Accordion 
	            		flush 
	            		defaultActiveKey="0" 
	            	>
	            		{renderlist}
	            	</Accordion>
	            </Col>
	        </Row>
		</Container>
	)
}